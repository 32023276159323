



































import MainNavbar from '@/components/ADSManager/MainNavbarComponent/MainNavbar.vue'
import CampaignPerformances from '@/components/ADSManager/MainComponent/CampaignPerformances.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Details from '@/components/ADSManager/Dashboard/CompaignDetails.vue'
import Delivery from '@/components/ADSManager/Dashboard/DeliveryOverview/Delivery.vue'
import Inspector from '@/components/ADSManager/Dashboard/AudienceInspector/AudienceInspector.vue'
import TopFactors from '@/components/ADSManager/Dashboard/TopFactors/TopFactors.vue'
import CurrentTarget from '@/components/ADSManager/Dashboard/CurrentTarget/CurrentTarget.vue'
import WeekHours from '@/components/ADSManager/Dashboard/TopFactors/WeekHours.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    WeekHours,
    CurrentTarget,
    TopFactors,
    Inspector,
    Delivery,
    Details,
    CampaignPerformances,
    MainNavbar,
    DatePicker
  },
  props: {
    campaign_id: {
      required: true
    }
  },

  data () {
    return {
      startDate: null,
      endDate: null,
      flag: true,
      preloading: false,
      preloadingBestPeriod: false,
      preloadingTopFactors: false,
      preloadingTargeting: false,
      dates: [
        this.$moment(new Date()).subtract(10, 'days').format('DD-MM-YYYY'),
        this.$moment(new Date()).format('DD-MM-YYYY')
      ],
      dueDates: []
    }
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', ['campaignPerformanceDueDate']),
    ...mapGetters('adsManager/campaignDashboard', ['getCampaignDetails', 'campaignPerformanceOrderedTitleSortedTrimmed']),
    campaignName () {
      return this.getCampaignDetails?.data?.attributes?.name
    },
    dateRange: {
      get () {
        return this.campaignPerformanceDueDate.length > 0
          ? this.campaignPerformanceDueDate
          : this.dates
      },
      set (v) {
        this.preloading = true
        this.flag = false
        this.setStateProp({ prop: 'campaignPerformanceDueDate', value: v })
        this.get_campaign_performance({
          id: this.campaign_id,
          startDate: this.campaignPerformanceDueDate.length > 0 ? this.campaignPerformanceDueDate[0] : v[0],
          endDate: this.campaignPerformanceDueDate.length > 0 ? this.campaignPerformanceDueDate[1] : v[1]
        })
          .then(() => {
            const qwe = this.$store.state.adsManager.campaignDashboard.campaignPerformanceChartsOptions
            this.setStateProp({ prop: 'campaignPerformanceChartsOptions', value: '' })
            this.flag = true
            this.setStateProp({ prop: 'campaignPerformanceChartsOptions', value: qwe })
            this.dates = [v[0], v[1]]
            this.addChart({
              color: '#12B1A0',
              prop: this.campaignPerformanceOrderedTitleSortedTrimmed[0].name,
              title: this.campaignPerformanceOrderedTitleSortedTrimmed[0].title
            })
            this.preloading = false
          })
      }
    }
  },

  methods: {
    ...mapActions('notifications', ['addNotification', 'addSuccessNotification']),
    ...mapActions('budget', ['load_adSet_spendCap']),
    ...mapMutations('adsManager/campaignDashboard', [
      'setStateProp',
      'addChart'
    ]),
    ...mapActions('adsManager', ['load_campaign_targeting']),
    ...mapActions('adsManager/campaignDashboard', [
      'get_campaign_details',
      'get_campaign_performance',
      'get_campaign_targeting',
      'get_campaign_best_period',
      'get_campaign_top_factors',
      'load_campaigns',
      'get_report_adSets'
    ]),
    disabledDates (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date > today
    },

    getCampaign (): void {
      this.get_campaign({
        start_date: this.startDate,
        stop_date: this.endDate = this.$moment().format('DD.MM.YYYY'),
        page: this.page || 1
      })
    }
  },

  watch: {
    dateRange (): void {
      this.startDate = this.dateRange[0]
    }
  },

  mounted (): void {
    this.preloading = true
    this.preloadingBestPeriod = true
    this.preloadingTargeting = true
    this.preloadingTopFactors = true
    this.get_campaign_details(this.campaign_id)
    this.load_adSet_spendCap({ id: this.campaign_id })
    this.get_report_adSets({ id: this.campaign_id }).then(() => {
      this.preloadingTargeting = false
    }).catch(() => {
      this.preloadingTargeting = false
    })
    // for performanse
    this.get_campaign_performance({
      id: this.campaign_id,
      startDate: this.campaignPerformanceDueDate.length > 0 ? this.campaignPerformanceDueDate[0] : this.dates[0],
      endDate: this.campaignPerformanceDueDate.length > 0 ? this.campaignPerformanceDueDate[1] : this.dates[1]
    }).then(() => {
      this.preloading = false
    }).catch(() => {
      this.addNotification({ type: 'danger', body: 'Oops, failed to load campaign performance. Please try again later' })
    })
    this.get_campaign_top_factors({
      id: this.campaign_id,
      startDate: this.dates[0],
      endDate: this.dates[1]
    }).then(() => {
      this.preloadingTopFactors = false
    }).catch(() => {
      this.addNotification({ type: 'danger', body: 'Oops, failed to load campaign top factors. Please try again later' })
    })
    this.get_campaign_best_period(this.campaign_id)
      .then(() => {
        this.preloadingBestPeriod = false
      }).catch(() => {
        this.addNotification({ type: 'danger', body: 'Oops, failed to load campaign best period. Please try again later' })
      })
    // this.get_campaign_targeting(this.campaign_id)
    //   .then(() => {
    //     this.preloading = false
    //   }).catch(() => {
    //     this.addNotification({ type: 'danger', body: 'Oops, failed to load campaign targeting. Please try again later' })
    //   })
  }
}
