<template>
  <div class="example">
    <apexcharts width="295"
                ref="realtimeChart"
                height="70"
                :type="'area'"
                :options="chartOptions"
                :series="series">
    </apexcharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DeliveryChart',
  components: {
    apexcharts: VueApexCharts
  },
  props: {
    options: Array,
    propName: String
  },
  watch: {
    options () {
      this.updateChart()
      // this.type = 'line'
      // setTimeout(() => {
      //   this.type = 'area'
      // })
    }
  },
  methods: {
    updateChart () {
      this.series = this.options
    }
  },
  data () {
    return {
      type: 'area',
      chartOptions: {
        chart: {
          sparkline: {
            enabled: true
          },
          id: 'basic-bar',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: this.colors,
        legend: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        markers: {
          hover: {
            sizeOffset: 0
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          curve: 'straight',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0
        },
        grid: {
          borderColor: '#EAEAEA'
        },
        xaxis: {
          show: false,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          categories: null,
          crosshairs: {
            show: false
          }
        },
        yaxis: {
          axisBorder: {
            show: true,
            color: '#CFCFCF',
            offsetX: 0,
            offsetY: 0
          }
        }
      },
      series: this.options
    }
  }
}
//
</script>

<style scoped lang="scss">
.example {
  height: 100%;
  }

</style>
