var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"del_container"},[_c('h4',[_vm._v("Delivery overview")]),(!_vm.preloading && _vm.emptyChecker(_vm.campaignPerformance))?_c('div',{staticClass:"del_main-content"},[_c('p',[_vm._v("This campaign does not yet have data to display in Delivery overview")])]):_vm._e(),(_vm.preloading || _vm.notEmptyChecker(_vm.campaignPerformance))?_c('div',{staticClass:"del_main-content"},[_c('div',{staticClass:"del_chart"},[_c('div',{staticClass:"chart_info"},[_c('div',{staticClass:"info_title"},[_c('img',{attrs:{"src":require("@/assets/ads-manager/reach-icon.svg"),"alt":""}}),_vm._v(" Reach ")]),(!_vm.preloading && _vm.campaignPerformanceOrdered.length > 0)?_c('span',[_vm._v(_vm._s(_vm.campaignPerformanceOrdered.find(function (v) { return v.title === 'Reach'; }).value))]):_vm._e(),(_vm.preloading)?_c('PreloaderLine',{staticClass:"mt-3"}):_vm._e()],1),(_vm.preloading)?_c('div',{staticClass:"del_chart_container"}):_vm._e(),(!_vm.preloading && _vm.campaignPerformance)?_c('div',{staticClass:"del_chart_container"},[_c('DeliveryChart',{attrs:{"options":[{
          id: 1,
          name: 'Reach',
          data: Object.values(_vm.campaignPerformance.day).map(function (item) { return item['reach']; }),
          color: '#00A65A'
        }]}})],1):_vm._e()]),_c('div',{staticClass:"chart_vertical_line"}),_c('div',{staticClass:"del_chart_left"},[_c('div',{staticClass:"chart_info"},[_c('div',{staticClass:"info_title"},[_c('img',{attrs:{"src":require("@/assets/ads-manager/frequency-icon.svg"),"alt":""}}),_vm._v(" Frequency ")]),(!_vm.preloading && _vm.campaignPerformanceOrdered.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.campaignPerformanceOrdered.find(function (v) { return v.title === 'Frequency'; }).value)+" ")]):_vm._e(),(_vm.preloading)?_c('PreloaderLine',{staticClass:"mt-3"}):_vm._e()],1),(_vm.preloading)?_c('div',{staticClass:"del_chart_container"}):_vm._e(),(!_vm.preloading && _vm.campaignPerformance)?_c('div',{staticClass:"del_chart_container"},[_c('DeliveryChart',{attrs:{"options":[{
          id: 2,
          name: 'Frequency',
          data: Object.values(_vm.campaignPerformance.day).map(function (item) { return item['frequency']; }),
          color: '#007BFF'
      }]}})],1):_vm._e()])]):_vm._e(),(!_vm.preloading && _vm.notEmptyChecker(_vm.campaignPerformance))?_c('div',{staticClass:"del_container_footer"},[_c('div',[_c('span',{staticClass:"cam_item_name"},[_vm._v("Time Zone: ")]),_c('span',{staticClass:"cam_item_value"},[_vm._v(_vm._s(_vm.campaignPerformanceMeta.timezone))])]),_c('div',[_c('span',{staticClass:"cam_item_name"},[_vm._v("Last Update: ")]),_c('span',{staticClass:"cam_item_value"},[_vm._v(_vm._s(_vm.campaignPerformanceMeta.lastUpdate))])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }