

















































import Vue from 'vue'
import Columns from '@/components/ADSManager/MainComponent/Columns.vue'
import Chart from '@/components/ADSManager/MainComponent/Charts/Chart.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import { mapGetters, mapState, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'CampaignPerformances',
  components: { Chart, Columns, BasePagePreloader },
  props: {
    title: String,
    hideButton: Boolean,
    preloading: Boolean
  },
  data () {
    return {
      open: true,
      dataOption: []
    }
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', ['campaignPerformance', 'campaignPerformanceMeta', 'campaignPerformanceChartsOptions']),
    ...mapGetters('adsManager/campaignDashboard', ['campaignPerformanceOrderedTitleSortedTrimmed']),
    ...mapGetters('adsManager', ['getSelectedAdAccount']),
    timezone () {
      return this.getSelectedAdAccount?.timezone
    }
  },
  methods: {
    ...mapMutations('adsManager/campaignDashboard', ['removeChart']),
    emptyChecker (arr) {
      if (arr.length !== 0) {
        if (Object.keys(arr['day']).length === 0) {
          return true
        }
      } else {
        return false
      }
    },
    notEmptyChecker (arr) {
      if (arr.length !== 0) {
        if (Object.keys(arr['day']).length > 0) {
          return true
        }
      } else {
        return false
      }
    },
    selectEvent (name) {
      this.removeChart(name)
    }
  }
})
