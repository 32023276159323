







































import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'Table',
  components: { VSelect },
  props: {
    costPerAction: {
      default: ''
    }
  },
  data () {
    return {
      selected: '',
      tableOptions: {
        headers: [
          'CTR', 'CPC', 'Clicks', 'Impression', 'Spent', 'ROI', 'Conversion', 'Conv. - CPA'
        ]
      }
    }
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', ['campaignPerformanceAudienceInspector']),
    ...mapGetters('adsManager/campaignDashboard', ['availableBreakdowns', 'sortedData'])
  },
  methods: {
    ...mapMutations('adsManager/campaignDashboard', ['setAudienceInspectorProp']),
    round_insight (n, digits = 2) {
      if (typeof n === 'string') {
        return parseFloat(n).toFixed(digits)
      }
      return n ? n.toFixed(digits) : n
    }
  }
})

