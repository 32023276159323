

































































































































import Vue from 'vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'DetailsTablet',
  data () {
    return {
      open: true,
      not_set: 'Not set'
    }
  },
  props: {
    campaignDetail: {
      default: {}
    },
    preloading: {
      default: false
    }
  },
  computed: {
    ...mapGetters('adAccounts', ['getById']),
    budget_obj () {
      return this.campaignDetail.included ? this.campaignDetail.included?.find(i => i.type === 'budget') : null
    },
    account_name () {
      return this.account?.name ? this.account.name : 'Not found'
    },
    cbo_on () {
      return this.campaignDetail?.data?.attributes?.budget_optimization
    },
    account () {
      return this.getById(Number(this.campaignDetail?.data?.attributes?.account_id))
    }
  },
  methods: {
    budget_attribute (key, placeholder = this.not_set) {
      if (key === 'amount') {
        if (!this.cbo_on) { return 'Using ad sets budget' } else {
          return this.budget_obj ? `${this.budget_obj.attributes[key]} ${this.account?.currency ? this.account.currency : ''}` : placeholder
        }
      }
      return this.budget_obj ? this.budget_obj.attributes[key] : placeholder
    },
    campaign_attribute (key, placeholder = this.not_set) {
      return this.campaignDetail ? this.campaignDetail.data?.attributes[key] : placeholder
    },
    budgetType () {
      if (!this.campaignDetail?.data?.attributes) {
        return ''
      }
      if (this.campaignDetail.data.attributes.daily_budget) {
        return 'Daily'
      } else {
        return 'Lifetime'
      }
    },
    textTransform (text) {
      return _.capitalize(_.lowerCase(text))
    },
    openClose () {
      this.open = !this.open
    }
  }
})

