


































































import Vue from 'vue'
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'Columns',
  components: { BaseDropdown },
  props: {
    open: Boolean,
    preloading: Boolean
  },
  methods: {
    ...mapMutations('adsManager/campaignDashboard', ['overridePerformanceChartTitles', 'addChart', 'changeShowState', 'removeChart']),
    check (event, data, ind) {
      const color = this.colorConverter(ind)
      this.addChart({ prop: data.name, title: data.title, color: color })
      this.changeShowState({ name: data.name, showState: event.target.checked })
    },
    changeColumns (columnId, itemId, columnName, itemName, ind, itemTitle) {
      this.overridePerformanceChartTitles({ replaceableId: columnId, substituteId: itemId })
      if (this.campaignPerformanceChartsOptions.filter(v => v.name === columnName).length > 0) {
        this.removeChart(columnName)
        this.addChart({ prop: itemName, title: itemTitle, color: this.colorConverter(ind) })
      }
    },
    colorConverter (index) {
      let color
      switch (index) {
        case 0:
          color = '#12B1A0'
          break
        case 1:
          color = '#fb9700'
          break
        case 2:
          color = '#b38135'
          break
        case 3:
          color = '#fc355f'
          break
        case 4:
          color = '#38acff'
          break
      }
      return color
    },
    emptyChecker (arr) {
      if (arr.length !== 0) {
        if (Object.keys(arr['day']).length === 0) {
          return true
        }
      } else {
        return false
      }
    },
    notEmptyChecker (arr) {
      if (arr.length !== 0) {
        if (Object.keys(arr['day']).length > 0) {
          return true
        }
      } else {
        return false
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', ['campaignPerformance', 'campaignPerformanceOrdered', 'campaignPerformanceChartsOptions']),
    ...mapGetters('adsManager/campaignDashboard', ['campaignPerformanceOrderedTitleSortedTrimmed'])
  }
})
