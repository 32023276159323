






























































import Vue from 'vue'
import DeliveryChart from '@/components/ADSManager/Dashboard/DeliveryOverview/Charts.vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'Delivery',
  components: { DeliveryChart },
  data () {
    return {}
  },
  props: {
    preloading: {
      default: false
    }
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', [
      'campaignPerformance',
      'campaignPerformanceOrdered',
      'campaignPerformanceMeta'
    ])
  },
  methods: {
    emptyChecker (arr) {
      if (arr.length !== 0) {
        if (Object.keys(arr['day']).length === 0) {
          return true
        }
      } else {
        return false
      }
    },
    notEmptyChecker (arr) {
      if (arr.length !== 0) {
        if (Object.keys(arr['day']).length > 0) {
          return true
        }
      } else {
        return false
      }
    }
  }
})
