import { render, staticRenderFns } from "./DashboardTargetContent.vue?vue&type=template&id=946cf20c&scoped=true&"
import script from "./DashboardTargetContent.vue?vue&type=script&lang=ts&"
export * from "./DashboardTargetContent.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardTargetContent.vue?vue&type=style&index=0&id=946cf20c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "946cf20c",
  null
  
)

export default component.exports