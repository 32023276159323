


























import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import Table from '@/components/ADSManager/Dashboard/AudienceInspector/Table.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'Inspector',
  components: { Table, VSelect },
  data () {
    return {}
  },
  props: {
    preloading: {
      default: false
    }
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', ['campaignPerformanceAudienceInspector']),
    ...mapGetters('adsManager/campaignDashboard', ['availableBreakdowns'])
  },
  methods: {
    ...mapActions('adsManager/campaignDashboard', ['get_campaign_audience_inspector']),
    ...mapMutations('adsManager/campaignDashboard', ['setAudienceInspectorProp'])
  },
  mounted () {
    this.get_campaign_audience_inspector(this.$route.params.campaign_id).then(() => {
      this.selected = this.campaignPerformanceAudienceInspector.costPerActions[0]
      this.setAudienceInspectorProp({ prop: 'selectedCostPerAction', value: this.campaignPerformanceAudienceInspector.costPerActions[0] })
      this.setAudienceInspectorProp({ prop: 'selectedBreakdown', value: this.availableBreakdowns[0] })
    })
  }
})

