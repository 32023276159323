














import Vue from 'vue'
import TabSwitcher from '../../../../components/TabSwitcher.vue'
import FactorsTable from '../../../../components/ADSManager/Dashboard/TopFactors/Table.vue'

export default Vue.extend({
  name: 'TopFactorsAdset',
  data () {
    return {
      activeTab: ''
    }
  },
  components: { TabSwitcher, FactorsTable },
  props: {
    tabMenu: {
      default: {}
    }
  },
  methods: {
    switchTabFromSelect (acc) {
      this.activeTab = {
        index: 0,
        name: acc,
        activeTab: this.tabMenu.find(v => v.name === acc)
      }
    },
    switchTab (acc) {
      this.activeTab = acc
    }
  },
  created () {
    this.activeTab = {
      index: 0,
      name: this.tabMenu[0].name,
      activeTab: this.tabMenu[0]
    }
  }
})
