











































































































import Vue from 'vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import TargetContentLocationNew from '@/components/ADSManager/Dashboard/CurrentTarget/TargetContentLocationNew.vue'
import TargetContentPlacement from '@/components/ADSManager/Dashboard/CurrentTarget/TargetContentPlacements.vue'
import TargetContentDetailed from '@/components/ADSManager/Dashboard/CurrentTarget/TargetContentDetailed.vue'
export default Vue.extend({
  name: 'DashboardTargetContent',
  data () {
    return {}
  },
  components: {
    TargetContentLocationNew,
    TargetContentPlacement,
    TargetContentDetailed
  },
  props: {
    preloading: Boolean,
    borderBottom: Boolean,
    ages: Array,
    location: Array,
    languages: Array,
    placements: {
      default () {
        return []
      }
    },
    detailedTargeting: Array,
    allAds: Boolean,
    genders: Array,
    include: Array,
    exlude: Array
  },
  computed: {
    ...mapGetters('adsManager/campaignDashboard', ['getLocaleName']),
    includeLocations () {
      if (this.adSet?.formatted_targeting?.locations) {
        return this.adSet.formatted_targeting.locations.filter(l => l.is_included)
      } else {
        return null
      }
    }
  },
  methods: {
    genderName (number) {
      if (number === 0) {
        return 'Male and Female'
      }
      if (number === 1) {
        return 'Male'
      }
      if (number === 2) {
        return 'Female'
      }
    },
    textTransform (text) {
      return _.capitalize(_.lowerCase(text))
    },
    getFilteredLocation (index, include_status) {
      return {
        countries: this.location[index]
          .filter(v => v.is_included === include_status)
          .filter((l) => l.type === 'country'),
        cities: this.location[index]
          .filter(v => v.is_included === include_status)
          .filter((l) => l.type === 'city')
          .sort((f, s) => f.country !== s.country ? f.country > s.country : f.region > s.region),
        others: this.location[index]
          .filter(v => v.is_included === include_status)
          .filter((l) => l.type !== 'city' && l.type !== 'country')
      }
    }
  }
})
