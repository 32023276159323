



























import Vue from 'vue'

import FactorsTable from '@/components/ADSManager/Dashboard/TopFactors/Table.vue'
import TopFactorAdset from '@/components/ADSManager/Dashboard/TopFactors/TopFactorAdSet.vue'

import { mapState } from 'vuex'

export default Vue.extend({
  name: 'TopFactors',
  components: { TopFactorAdset },
  data () {
    return {
      percentIndicator: [36, 45, 19],
      footerItem: {
        name: 'Last update: ',
        value: '6 minutes ago'
      },
      selectTab: [
        { name: 'Text', value: true },
        { name: 'Headline', value: false },
        { name: 'Call', value: false }
      ]
    }
  },
  props: {
    preloading: {
      default: false
    }
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', ['topFactors'])
  },
  methods: {}
})
