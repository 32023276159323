










































import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'WeekHours',
  data () {
    return {
      footerItems: [
        { name: 'Last update: ', value: '6 minutes ago' }
      ],
      statistics: [
        { name: 'CTR', value: `${0.0000}%` },
        { name: 'CPC', value: '0.00' },
        { name: 'Clicks', value: '0.00' },
        { name: 'Impressions', value: '0' }
      ],
      dataWeek: [],
      week: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      dataHours: [],
      counter: 0,
      activeStat: '',
      favoriteMetrics: ['ctr', 'cpc', 'impressions']
    }
  },
  props: {
    preloading: {
      default: false
    }
  },
  computed: {
    ...mapGetters('adsManager/campaignDashboard', ['getMainMetric'])
  },
  methods: {
    activateStat (data) {
      const newData = {}
      for (const item in data) {
        newData[item] = data[item]
        // alert(item)
        // alert(data[item])
      }
      this.activeStat = newData
    },
    setDataHours (counter) {
      while (counter < 24) {
        this.dataHours.push({ name: `${counter < 10 ? `0${counter}` : `${counter}`}`, clicked: false })
        counter++
      }
    },
    setDataWeek (counter) {
      while (counter < 7) {
        this.dataWeek.push({ name: `${this.week[counter]}`, clicked: false })
        counter++
      }
    },
    onClickWeek (index) {
      this.dataWeek[index].clicked = !this.dataWeek[index].clicked
    },
    onClickHour (index) {
      this.dataHours[index].clicked = !this.dataHours[index].clicked
    }
  },
  mounted () {
    this.setDataWeek(this.counter)
    this.setDataHours(this.counter)
  }
})
