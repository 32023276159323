var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"target_container"},[_c('div',{staticClass:"current_content"},[_c('h4',[_vm._v("Current Target")]),(_vm.getBudgetsAdSetSpendCap.length)?_c('DashboardTargetContent',{attrs:{"borderBottom":false,"preloading":_vm.preloading,"location":_vm.dashboardAdSets.map(function (v) { return v.attributes.formatted_targeting.locations; }),"ages":_vm.dashboardAdSets.map(function (v) {
        return {
          min: v.attributes.formatted_targeting.age_min,
          max: v.attributes.formatted_targeting.age_max
        } }),"genders":_vm.dashboardAdSets.map(function (v) { return v.attributes.formatted_targeting.genders; }),"languages":_vm.dashboardAdSets.map(function (v) { return v.attributes.formatted_targeting.locales; }),"placements":_vm.dashboardAdSets.map(function (v) { return v.attributes.formatted_targeting.ad_placements; })[0],"detailedTargeting":_vm.dashboardAdSets.map(function (v) {
        return {
          inclusion_targeting: v.attributes.formatted_targeting.inclusion_targeting,
          exclusion_targeting: v.attributes.formatted_targeting.exclusion_targeting
        }
      })}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }