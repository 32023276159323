



























import Vue from 'vue'
import DashboardTargetContent from '@/components/ADSManager/Dashboard/CurrentTarget/DashboardTargetContent.vue'
import _ from 'lodash'
import { mapGetters, mapState, mapActions } from 'vuex'
export default Vue.extend({
  name: 'CurrentTarget',
  components: {
    DashboardTargetContent
  },
  data () {
    return {}
  },
  props: {
    preloading: {
      default: false
    }
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', ['campaignTargeting', 'campaignPerformance', 'dashboardAdSets']),
    ...mapGetters('budget', ['getBudgetsAdSetSpendCap'])
  },
  methods: {
    unique (arr) {
      return _.uniq(arr)
    }
  }
})
