


















































import Vue from 'vue'
import _ from 'lodash'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

export default Vue.extend({
  name: 'FactorsTable',
  components: { VSelect },
  props: {
    select: {
      default: ''
    },
    tableData: {
      default: []
    },
    tabMenu: {
      default: []
    }
  },
  data () {
    return {
      placeholder: 'Headlines',
      selectTab: ''
    }
  },
  computed: {
    titles () {
      const t = []
      if (this.tableData.activeTab.data) {
        this.tableData.activeTab.data.forEach(i => {
          t.push(Object.keys(i.insights))
        })
      }
      return _.uniq(t.flat())
    }
  },
  methods: {
    nameConverter (name) {
      const n = []
      n.push(name.split('')[0].toUpperCase())
      n.push(name.split('').slice(1, name.split('').length))
      return n.flat().join('').split('_').join(' ')
    }
  },
  mounted () {
    this.selectTab = this.tabMenu.map(v => v.name)[0]
  }
})

