









import Vue from 'vue'

import DetailsTablet from '@/components/ADSManager/Dashboard/DetailsTable.vue'

import { mapState } from 'vuex'

export default Vue.extend({
  name: 'Details',
  components: { DetailsTablet },
  data () {
    return {
      open: true
    }
  },
  props: {
    preloading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('adsManager/campaignDashboard', ['campaignDetail'])
  }
})
